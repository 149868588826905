import React from 'react'
import { Link, graphql } from 'gatsby'
import _get from 'lodash/get'
import styled from 'styled-components'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { rhythm } from '../utils/typography'
import Tag from '../components/Tag'

import { LIGHT_THEME, DARK_THEME } from '../utils/theme'

const Wrapper = styled.div`
  article,
  section.seo,
  section.comments,
  nav {
    margin: 0 auto;
    max-width: ${rhythm(30)};
    padding: ${rhythm(3 / 4)};
  }

  article {
    header {
      h1 {
        text-align: center;
        font-weight: 700;
        font-size: 2.5em;
        letter-spacing: -0.025em;
        line-height: 1.3;
        margin-top: ${rhythm(1.5)};
        margin-bottom: ${rhythm(0.5)};
      }
      p {
        font-family: georgia, serif;
        text-align: center;
        display: block;
        margin-bottom: ${rhythm(1)};
        color: ${props =>
          props.theme && props.theme.color === 'dark'
            ? DARK_THEME.postDateColor
            : LIGHT_THEME.postDateColor};
        span {
          font-style: normal;
          margin-right: 5px;
        }
      }
    }

    table {
      color: ${props =>
        props.theme && props.theme.color === 'dark'
          ? DARK_THEME.textColor
          : LIGHT_THEME.textColor};
      th,
      td {
        border-bottom: ${props =>
          props.theme && props.theme.color === 'dark'
            ? `1px solid ${DARK_THEME.textColor}`
            : '1px solid iherit'};
      }
    }

    code.language-text {
      padding: 2px 5px;
    }
  }

  .cover {
    &::before {
      content: '';
      display: block;
      height: calc(100vh - 42px);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      background-image: linear-gradient(
          rgba(0, 0, 0, 0.25),
          rgba(0, 0, 0, 0.25)
        ),
        url(${props => props.coveredImage});
      opacity: 0.99;
    }
  }

  .coveredImageAuthor {
    color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.postDateColor
        : LIGHT_THEME.postDateColor};
    text-align: center;
    margin-top: ${rhythm(0.3)};
  }
  section.content {
    margin-top: 1.5rem;
  }
  hr {
    margin-bottom: ${rhythm(1)};
  }
  nav {
    ul {
      margin-left: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      a {
        color: ${props =>
          props.theme && props.theme.color === 'dark'
            ? DARK_THEME.textColor
            : LIGHT_THEME.textColor};
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .cover {
      &::before {
        height: 225px;
      }
    }

    nav {
      ul {
        justify-content: center;
      }
    }
  }

  @media (min-width: 426px) and (max-width: 1024px) {
    .cover {
      &::before {
        height: 445px;
      }
    }
  }
`

function BlogPostTemplate(props) {
  const post = _get(props, 'data.markdownRemark')
  const siteTitle = _get(props, 'data.site.siteMetadata.title')
  const previous = _get(props, 'pageContext.previous')
  const next = _get(props, 'pageContext.next')
  const tags = _get(post, 'frontmatter.tags')
  const coveredImgFluid = _get(
    post,
    'frontmatter.coveredImage.childImageSharp.fluid',
    ''
  )

  const coveredImageAuthor = _get(
    post,
    'frontmatter.coveredImageAuthor',
    'unknown'
  )

  return (
    <Layout fullWidth location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Wrapper coveredImage={coveredImgFluid.src}>
        {coveredImgFluid && (
          <section>
            <div className="cover"></div>
            {coveredImageAuthor && (
              <div className="coveredImageAuthor">
                <small>{coveredImageAuthor}</small>
              </div>
            )}
          </section>
        )}
        <article>
          <header>
            <h1>{post.frontmatter.title}</h1>
            <p>
              <span>📅</span>
              {post.frontmatter.date}
            </p>
          </header>

          {tags && tags.map((tag, index) => <Tag tag={tag} key={index} />)}

          <section
            className="content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr />

          {tags && tags.map((tag, index) => <Tag tag={tag} key={index} />)}

          <footer>
            <Bio />
          </footer>
        </article>

        <nav>
          <ul>
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
        {/* <section className="comments">
          <Disqus config={disqusConfig} />
        </section> */}
      </Wrapper>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        coveredImage {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        coveredImageAuthor
      }
    }
  }
`
