import React from 'react'
import styled from 'styled-components'
import slug from 'slug'
import { Link } from 'gatsby'

import { LIGHT_THEME, DARK_THEME } from '../utils/theme'

const Wrapper = styled.div`
  margin-right: 5px;
  display: inline-block;
  span {
    font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
    color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.tagColor
        : LIGHT_THEME.tagColor};
    border-radius: 0.3em;
    font-weight: bold;
  }

  @media only screen and (max-width: 672px) {
    span {
      font-weight: 400;
    }
  }
`

function Tag({ tag }) {
  return (
    <Wrapper>
      <Link to={`/tag/${slug(tag).toLowerCase()}/`}>
        <span>[{tag}]</span>
      </Link>
    </Wrapper>
  )
}

export default Tag
